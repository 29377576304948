.letters-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f5; /* Gris claro pastel de fondo */
  padding: 10px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  background-color: #e6e6fa; /* Fondo del header */
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.calendar-header h2 {
  margin: 0;
  font-size: 24px;
  color: #5e35b1;
  font-weight: bold;
}

.calendar-header button {
  background-color: #d1c4e9; /* Botones morados pastel */
  border: none;
  color: #5e35b1;
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.calendar-header button:hover {
  background-color: #b39ddb; /* Más oscuro al hacer hover */
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  width: 100%;
  max-width: 1200px;
  color: #5e35b1;
  border: 1px solid #ddd;
  background-color: #e6e6fa; /* Fondo gris claro detrás del calendario */
}

.calendar-day {
  background-color: #faf4ff; /* Morado pastel claro para los días */
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  font-size: 16px;
  color: #5e35b1;
  position: relative;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.calendar-day:hover {
  background-color: #e0bbf3;
}

.blank {
  background-color: transparent;
  cursor: default;
}

.has-letter {
  background-color: #b39ddb;
}

.calendar-heart {
  font-size: 18px;
  margin-top: 5px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #8a2be2;
}

/* Responsividad para pantallas más pequeñas */
@media (max-width: 768px) {
  .calendar-header h2 {
    font-size: 20px;
  }

  .calendar-header button {
    font-size: 16px;
    padding: 8px;
  }

  .calendar-grid {
    gap: 5px;
  }

  .calendar-day {
    padding: 10px;
    font-size: 14px;
  }

  .calendar-heart {
    font-size: 14px;
  }
}

/* Modal styles */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  background-color: #f4f1ea;
  padding: 20px;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  text-align: left;
  font-family: "Georgia", serif;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  animation: slideDown 0.5s ease;
}

.close {
  font-size: 24px;
  cursor: pointer;
  float: right;
  margin-top: -10px;
  color: #000000;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
